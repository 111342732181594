<template>
    <v-container style="background:white;" class="text-center">
        <div class="pt-6">
        ¿A que correo quieres mandar esta cotización?
        </div>
        <v-autocomplete clearable v-model="email" :items="emailLists" label="Email" item-text="email" item-value="email" style="max-width:250px!important; margin:auto;">
            <template slot="no-data">
                <div class="px-4 py-1">
                    No existen correos relacionados. 
                    <v-text-field v-model="email" type="email" label="Otro"></v-text-field>
                </div>
            </template>                      
        </v-autocomplete>
        <v-btn text color="primary" @click="sendEmail()">
        Enviar
        </v-btn>
    </v-container>
</template>

<script>
    import emailjs from 'emailjs-com';
    import{ init } from 'emailjs-com';
    init("user_WUxyGFs4hsxhFsnulrFVq");
    export default {
        props:{
            quotation:Object
        }, 
        data: () => ({
            email:''
        }),
        computed:{
            emailLists(){
                return this.$store.state.company.companies.filter(company=>company.id == this.quotation.company_id &&company.email!=null).concat(this.$store.state.contact.contacts.filter(contact=>contact.company_id == this.quotation.company_id))
            }
        },
        created(){
            console.log({
                    company: this.company(this.quotation.company_id),
                    email: this.email,
                    items: this.table(this.quotation.items),
                    valor: this.quotation.amount.toLocaleString('es-MX', { style: 'currency', currency: 'MXN',}),
                    reply_to: this.user(this.quotation.user_id).email,
                    currentUser: this.user(this.quotation.user_id).name
                })
        },
        methods:{
            sendEmail() {
                emailjs.send("service_glykmzr","template_ox74hnq",{
                    company: this.company(this.quotation.company_id),
                    email: this.email,
                    items: this.table(this.quotation.items),
                    valor: this.quotation.amount.toLocaleString('es-MX', { style: 'currency', currency: 'MXN',}),
                    reply_to: this.user(this.quotation.user_id).email,
                    currentUser: this.user(this.quotation.user_id).name
                }).then(response => {
                    location.reload();
                }).catch(error =>{ 
                    this.sendEmail()
                })
            },
            table(items){
                var products = ''
                for (let i = 0; i < items.length; i++) {
                        products = products +
                        '<strong>Cantidad: </strong>' + items[i].quantity + '<br/>' + 
                        '<strong>'+'Producto: '+'</strong>' + this.itemName(items[i].item) + '<br/>' + 
                        '<b>Totala = </b>' + this.money(items[i].value) + '<br/><br/>'
                }
                return products
            },
            company(id){
                return this.$store.state.company.companies.filter(company=>company.id == id).map(company=>company.name)[0]
            },
            user(id){
                return this.$store.state.user.users.filter(user=>user.id == id)[0]
            },
            money(amount){
                return (amount*1).toLocaleString('es-MX', { style: 'currency', currency: 'MXN',})
            },
            itemName(id){
                return this.$store.state.item.items.filter(item=>item.id == id).map(item => item.name)[0]
            }
        }
    }
</script>